import React from 'react'

import { Item, ItemGroup } from '../../../components/list/listItems'

import appConfig from 'config'

export default ({ title, channels, filters, onSelectChannel }) => (
	<div>
		<ItemGroup title={title}>
			{channels.map(channel => (
				<Item
					key={channel.reference}
					id={channel.id}
					actionData={channel}
					small={true}
					sel={filters.channel === (appConfig.features.schedulesUseChannelNameInsteadOfReference ? channel.providerName : channel.reference)}
					onSelect={() => onSelectChannel(channel)}
				>
					<div className="flex flex-dyn-1">
						<div className="c6-content">
							<h1>
								{channel.name}
								{channel.provider ? <span className="provider"> {channel.provider}</span> : ''}
							</h1>
						</div>
					</div>
				</Item>
			))}
		</ItemGroup>
	</div>
);