import React from 'react'
import { browserHistory } from 'browserHistory'
import appConfig from 'config'
import { withRouter } from 'react-router'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

import Editor, { parseUi } from '../../../../components/editor/'
import HourSchedule from '../../../../components/ui/controls/hourSchedule'
import DaySchedule from '../../../../components/ui/controls/daySchedule'
import StarUpload from '../../../../components/ui/editorWidgets/starUpload'
import Links from '../../../../components/ui/editorFields/linksNew'

import { hasAccessToPath } from '../../../../core/services/auth'
import * as API from '../../../../apis/'

import defaultSchema from './schema'
import editorialSchema from './editorialSchema'
import widgetSchema from './widgetSchema'
import curatedSchema from './curatedSchema' // regular selection list
import featureSchema from './featureSchema'
import automaticSchema from './automaticSchema'

import defaultUI from './uiSchema'
import editorialUI from './editorialUI'
import widgetUI from './widgetUI'
import curatedUI from './curatedUI' // selection
import featureUI from './featureUI'
import automaticUI from './automaticUI'

import { Regions } from '../../../../core/constants'
import SelectionsConstants from '../constants'
import UserStore from '../../../../core/authentication/store'
import SelectionsStore from '../../store'

import './app.css'

@withRouter
export default class ListEditorApp extends React.Component {

	state = {
		areas: null,
		group: {},
		versions: {},
		selectionsConfiguration: null,
	}

	isSysadmin = false

	componentDidMount() {
		const userState = UserStore.getState();
		this.isSysadmin = userState.user?.username === "sysadmin";

		//fetch and update state
		if (this.props.location.query?.groupId) {
			API.selections.fetchGroup(this.props.location.query.groupId)
				.then(group => this.setState({ group }));
		}

		if (this.props.params.serviceId >= 0) {
			API.selections.fetchAreas({ serviceId: this.props.params.serviceId })
				.then(areas => this.setState({ areas: areas?.items ?? [] }));
		}

		const configuration = SelectionsStore.getState().item.selectionsConfiguration;
		if (configuration) {
			this.setState({ selectionsConfiguration: configuration });
		} else {
			API.selections.fetchConfiguration()
				.then(config => this.setState({ selectionsConfiguration: config }));
		}
	}

	getSchema = (model, isNew, location, _route, params) => {
		const group = this.state.group;
		const versionId = model.version?.id ?? group?.version?.id;
		// TODO: Check selectionsConfiguration.serviceConfigurations[serviceId].regions to filter regions
		const regions = this.state.selectionsConfiguration?.availableRegions ?? Regions;
		if (versionId > 0) { // Only show localized fields for selected version
			model.localizations = regions
				.map(r => {
					const existingLocalization = model.localizations?.find(l => l.version.id === r.id);
					return {
						...existingLocalization,
						version: { id: r.id, name: r.languageDisplayName },
					};
				})
				.filter(l => l.version.id === versionId);
		} else { // Show all versions
			model.localizations = regions.map(r => {
				const existingLocalization = model.localizations?.find(l => l.version.id === r.id);
				return {
					...existingLocalization,
					version: { id: r.id, name: r.languageDisplayName }
				};
			});
		}

		if (!model.links?.length) {
			model.links = [{ type: "Undefined" }];
		}
		if (!model.assets?.length) {
			model.assets = [{ type: "Image", guid: "" }];
		}
		if (!model.publishTargets?.length) {
			model.publishTargets = [{}];
		}

		let schema;
		const category = isNew ? location.query.category : model.category?.name;
		switch (category) {
			case "regular":
			case "showcase":
				schema = curatedSchema;
				populateContentLayoutOptions(schema, this.state.selectionsConfiguration?.contentLayoutsForCategories?.[category]);
				setSchemaAudienceBasedOnGroup(schema, group);
				setVersionAndDropdown(schema, model.localizations, group);
				break;
			case "editorial":
				schema = editorialSchema;
				populateContentLayoutOptions(schema, this.state.selectionsConfiguration?.contentLayoutsForCategories?.[category]);
				setSchemaAudienceBasedOnGroup(schema, group);
				setVersionAndDropdown(schema, model.localizations, group);
				break;
			case "widget":
				schema = widgetSchema;
				if (appConfig.features.selectionsUseTemplate) {
					populateContentLayoutOptions(schema, this.state.selectionsConfiguration?.contentLayoutsForCategories?.[category]);
				}
				break;
			case "feature":
				schema = featureSchema;
				populateContentLayoutOptions(schema, this.state.selectionsConfiguration?.contentLayoutsForCategories?.[category]);
				break;
			case "genre":
			case "automatic-epg":
				// Use same contentLayout options as regular lists
				schema = automaticSchema;
				populateContentLayoutOptions(schema, this.state.selectionsConfiguration?.contentLayoutsForCategories?.["regular"]);
				break;
			default:
				schema = defaultSchema;
				break;
		}

		// HACK for partner service
		if (parseInt(params.serviceId) === 4) {
			const schemaCopy = cloneDeep(schema);
			delete schemaCopy.properties.version;
			delete schemaCopy.properties.audience;
			delete schemaCopy.properties.platforms;
			delete schemaCopy.properties.links;
			delete schemaCopy.properties.publishTargets;
			if (schemaCopy.properties.localizations) {
				delete schemaCopy.properties.localizations.items.properties.subtitle;
				delete schemaCopy.properties.localizations.items.properties.linkTitle;
				delete schemaCopy.properties.localizations.items.properties.active;
			}
			return schemaCopy;
		}

		return schema;
	}

	getUiSchema = (model, isNew, location, route, params) => {
		let ui;
		const category = isNew ? location.query.category : model.category?.name;
		switch (category) {
			case "regular":
			case "showcase":
				ui = parseUi(curatedUI, API);
				break;
			case "editorial":
				ui = parseUi(editorialUI, API);
				break;
			case "widget":
				ui = parseUi(widgetUI, API);
				break;
			case "feature":
				ui = parseUi(featureUI, API);
				break;
			case "genre":
			case "automatic-epg":
				ui = parseUi(automaticUI, API);
				break;
			default:
				ui = parseUi(defaultUI, API);
				break;
		}

		lockOrUnlockAudienceBasedOnSection(ui, this.state.group);

		if (isNew || this.isSysadmin) {
			ui.name["ui:readonly"] = false;
		}

		// HACK for partner service
		if (parseInt(params.serviceId) === 4) {
			const schemaCopy = cloneDeep(ui);
			if (parseInt(params.id) === 3796 && schemaCopy.assets) { // HERO item
				schemaCopy.assets.items.guid["ui:options"].assetCategoryId = 70;
				schemaCopy.assets.items.guid["ui:options"].cropTypeId = 1;
			}
			return schemaCopy;
		}

		return ui;
	}

	loadPayloadTransform = ({ model }) => {
		// Turn platforms string into 4 bool values
		const platformsArr = `${model.platforms ?? ""}` // Workaround since platforms sometimes returns as integer if some configuration is missing in the API
			.split(",")
			.map(platform => platform.trim());
		const platforms = {};
		SelectionsConstants.PLATFORMS.forEach(platform => platforms[platform.key] = platformsArr.findIndex(pl => pl.toLowerCase() === platform.key.toLowerCase()) >= 0);
		
		return {
			...model,
			platforms,
			publishTargets: model.publishTargets?.map(pt => ({
				...pt,
				// Lists that are "valid" forever have [from] set to 0001-01-01
				// but that will make our datepicker display 01-01-01 so we can treat it as undefined instead
				from: pt.from?.startsWith("0001-01-01") ? undefined : pt.from,
				until: pt.until?.startsWith("0001-01-01") ? undefined : pt.until,
			})),
			contentLayout: appConfig.features.selectionsUseTemplate ? model.template?.name : model.contentLayout,
		};
	}

	savePayloadTransform = ({ formData, location, ...rest }) => {
		const isNew = !formData?.id;
		const category = isNew ? location.query.category : formData.category?.name;

		// Turn platforms obj into string
		const platforms = Object.entries(formData?.platforms ?? {})
			.filter(([, value]) => !!value)
			.map(([key]) => key)
			.join(", ");

		const publishTargets = formData?.publishTargets?.map(pt => ({
			...pt,
			until: pt.until && (!pt.from || moment(pt.from).isBefore(moment(pt.until))) ? pt.until : null,
		}));

		const defaultAreaName = this.state.areas?.length
			? this.state.areas[0].name
			: "main";

		const payload = {
			...rest,
			formData: {
				...formData,
				instanceType: "static",
				sectionId: isNew ? location.query.groupId : 0 ?? 0,
				area: formData.area ?? defaultAreaName,
				links: formData.links?.filter(l => l.type !== "Undefined"),
				version: formData.version ?? this.state.group?.version,
				category: { name: category },
				platforms: platforms,
				publishTargets,
				template: appConfig.features.selectionsUseTemplate ? { name: formData.contentLayout } : formData.template,
			},
			location,
			queryString: location.search,
		};

		switch (category) {
			case "showcase":
				payload.formData.instanceType = "minutely";
				// No break because "regular" clause below should apply to showcase
			// eslint-disable-next-line no-fallthrough
			case "regular":
				payload.formData.platforms = payload.formData.platforms ?? "Web, Mobile, SmartTv";
				break;
			case "editorial":
				break;
		}

		if (!payload.formData.platforms?.length) {
			payload.formData.platforms = 0; // API expects 0 or "none" instead of empty string
		}

		return payload;
	}

	render() {
		return (
			<Editor
				layout="grid"
				api="selections"
				entity="list"
				getCommands={getCommands}
				getSchema={this.getSchema}
				getUiSchema={this.getUiSchema}
				customWidgets={{ daySchedule: DaySchedule, hourSchedule: HourSchedule, upload: StarUpload }}
				customFields={{ links: Links }}
				hasEditAccess={hasAccessToPath(this.props.routes, "editor")}
				savePayloadTransform={this.savePayloadTransform}
				loadPayloadTransform={this.loadPayloadTransform}
				getCreateAlertAction={getCreateAlertAction}
				{...this.props}
			/>
		);
	}
}

function getCommands({ location }) {
	return {
		fetchItem: ({ id }) => API.selections.fetchList(id),
		updateItem: ({ id }, payload) => API.selections.updateList(id, payload, location.query?.groupId),
	};
}

function setSchemaAudienceBasedOnGroup(schema, section) {
	const sectionAudience = section?.audience ?? "everyone";

	if (sectionAudience !== "everyone") {
		// Set default value to the sections audience value
		schema.properties.audience = {
			...schema.properties.audience,
			default: sectionAudience,
		};
	}
}

function lockOrUnlockAudienceBasedOnSection(ui, section) {
	if (!ui.audience) {
		return;
	}

	let sectionAudience = section?.audience || "everyone";
	let readOnly = !(sectionAudience === "everyone" || sectionAudience == "undefined");
	ui.audience["ui:readonly"] = readOnly;
}

function populateContentLayoutOptions(schema, contentLayoutsForCategory) {
	if (contentLayoutsForCategory) {
		schema.properties.contentLayout = {
			...schema.properties.contentLayout,
			oneOf: contentLayoutsForCategory.map(l => ({
				const: l.key,
				title: l.text,
			})),
			default: (contentLayoutsForCategory.find(l => l.default) ?? contentLayoutsForCategory[0])?.key,
		}
	}
}

function setVersionAndDropdown(schema, localizations, section) {
	const groupVersionId = section?.version?.id;

	if (groupVersionId === 0) {
		populateVersionDropdown(schema, localizations);
	} else if (groupVersionId > 0) {
		inheritVersionFromSectionAndFilterOptions(schema, section?.version);
	}
}

function populateVersionDropdown(schema, localizations) {
	if (schema.properties.version) {
		schema.properties.version.properties.id = {
			...schema.properties.version.properties.id,
			oneOf: localizations.map(l => ({
				const: l.version.id,
				title: l.version.name,
			})),
			default: localizations[0].version.id,
		};
	}
}

function inheritVersionFromSectionAndFilterOptions(schema, sectionVersion) {
	if (schema.properties.version) {
		schema.properties.version.properties.id = {
			...schema.properties.version.properties.id,
			oneOf: [
				{ const: 0, title: "All" },
				{ const: sectionVersion.id, title: sectionVersion.name },
			],
			default: sectionVersion.id,
		};
	}
}

function getCreateAlertAction({ model, groupId, params }) {
	if (model?.type === "curated") {
		const onClick = () => browserHistory.replace({
			query: { groupId },
			pathname: `/selections/service/${params.serviceId}/lists/${model.id}/items`,
		});
		return { title: "Start adding content to it.", onClick };
	}

	return null;
}