import { get, put, post, del } from '../core/c6'

const API = "orders";
// const SKIP_TOKEN = true;

/* **************** Orders ***************** */
export const fetchOrders 	   = filters 		    => get(API, "orders", filters);
export const fetchOrder 	   = ({id}) 		    => get(API, `orders/${id}`);
export const updateOrder       = ({id}, payload)    => put(API, `orders/${id}`, payload);
export const deleteOrder       = ({id})             => del(API, `orders/${id}`);
export const createOrder       = (payload)          => post(API, "orders", payload);