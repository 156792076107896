import React from 'react'

import ContentItem, { Content } from '../../../components/list/contentItem'
import { copyTextToClipboard } from '../../../utils/misc'

import '../../../components/listItems/standard.css'

import { getFileIcon, getContentLinkOrInfo, getResponseLinkOrInfo } from '../deliveries-classic/subListItem'

const SubItem = (props) => {
	const {
		deliveryItems,
	} = props;

	let content;
	if (!Array.isArray(deliveryItems)) {
		content = <Content>Loading...</Content>
	} else if (!deliveryItems.length) {
		content = <Content>Nothing to show here.</Content>
	} else {
		content = (
			<table style={{ width: "auto", padding: "7px" }}>
				<tbody>
					{deliveryItems.map(({ id, size, content, response, destinationPath }) => {
						const filenameRegex = /[^\/\\]+$/;
						const filename = destinationPath && filenameRegex.exec(destinationPath)[0] || "JSON";
						const fileIcon = getFileIcon(filename);
						return (
							<tr key={id} className="c6-content delivery-item">
								<td>
									<span
										className={`copy-on-click file-icon ${fileIcon}`}
										onClick={(e) => copyTextToClipboard(filename, "Copied file name to your clipboard!", e)}
										title="Click here to copy file name to your clipboard"
									>
										{filename}
									</span>
								</td>
								<td>{getContentLinkOrInfo(id, content, size, "vodcore")}</td>
								<td>{getResponseLinkOrInfo(id, response, "vodcore")}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	return (
		<ContentItem data={props} extraClasses="c6-secondary delivery-items">
			{content}
		</ContentItem>
	);
};

export default SubItem;