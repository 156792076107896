import React from 'react'

import App from '../../../components/app'
import Dashboard from '../../../components/dashboard/dashboard'
import { StandardInfo } from '../../../components/comet'

import appConfig from 'config'
import { displayAlert } from '../../../core/services/alert'

// Temp HACK: List should not be defined here, should be fetched from API
let SHORTCUTS;
if (appConfig.api.selections?.includes("tv4")) {
	SHORTCUTS = [
		{
			displayName: "First page",
			description: "",
			key: "selections.press-home",
			links: [
				{
					displayName: "Highlights list",
					description: "",
					key: "selections.press-fph",
					url: "/selections/press/lists/3743/items?serviceId=3",
					ui: "React",
				},
				{
					displayName: "Bulletin announcements",
					description: "",
					key: "selections.press-bb",
					url: "/selections/press/lists/3821/items?serviceId=3",
					ui: "React",
				},
			],
		},
		{
			displayName: "Current feature",
			description: "",
			key: "selections.press-cfh",
			url: "/selections/press/lists/3750/items?serviceId=3",
			urlText: "Highlights list",
			links: [],
		},
		{
			displayName: "Sports",
			description: "",
			key: "selections.press-sh",
			url: "/selections/press/lists/3762/items?serviceId=3",
			urlText: "Highlights list",
			links: [],
		},
		{
			displayName: "Contact us",
			description: "",
			key: "selections.press-pd",
			url: "/selections/press/lists/3770/items?serviceId=3",
			urlText: "Press people",
			links: [],
		},
		{
			displayName: "FAQ",
			description: "",
			key: "selections.press-faq",
			url: "/selections/press/lists/3783/items?serviceId=3",
			urlText: "Edit frequently asked questions",
			links: [],
		},
		{
			displayName: "Site admin",
			description: "",
			key: "selections.press-sa",
			url: "/selections/service/3",
			urlText: "Customize site",
			links: [],
		}
	];
} else {
	SHORTCUTS = [
		{
			displayName: "First page",
			description: "",
			key: "selections.press-fph",
			url: "/selections/press/lists/43/items",
			urlText: "Highlights list",
			links: [],
		},
		{
			displayName: "Current feature",
			description: "",
			key: "selections.press-cfh",
			url: "/selections/press/lists/11/items",
			urlText: "Highlights list",
			links: [],
		},
		{
			displayName: "Sports",
			description: "",
			key: "selections.press-sh",
			url: "/selections/press/lists/16/items",
			urlText: "Highlights list",
			links: [],
		},
		{
			displayName: "Contact us",
			description: "",
			key: "selections.press-pd",
			url: "/selections/press/lists/34/items",
			urlText: "Press people",
			links: [],
		},
		{
			displayName: "FAQ",
			description: "",
			key: "selections.press-faq",
			url: "/selections/press/lists/32/items",
			urlText: "Edit frequently asked questions",
			links: [],
		},
	];
}

class DashboardApp extends React.Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (appConfig.features.selectionsMovedToHeroComet) {
			displayAlert(
				"warning",
				null,
				null,
				null,
				60000,
				`Administration of the press service has been moved to <a href="https://tv4.junecomet.com">tv4.junecomet.com</a>`
			);
		}
	}

	render() {
		return (
			<App name="c6-selections-press" layout="grid">
				<Dashboard title="Press site administration" data={SHORTCUTS} />
				<StandardInfo location={this.props.location} />
			</App>
		);
	}
}

export default DashboardApp;