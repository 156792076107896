import React from 'react'

import Order from './order'
import itemActions from './itemActions'

import * as API from '../../../apis/orders'
import useListState from '../../../core/hooks/useList'
import GenericListApp from '../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../core/decorators/uiActions'

import './orders.css'

const MODULE = "orders";
const DATASTORE = "orders";
const TEXT_HEADING = "Orders";

const STATUS_TODO = "unhandled,confirmed,inProgress";
const STATUS_DONE = "delivered,completed";
const STATUS_CANCELED = "canceled";

function ListApp(props) {
	
	const filterConfig = {
		// searchText: {
		// 	type: "search",
		// 	alwaysVisible: true,
		// },
		orderCustomers: {
			type: "dropdown",
			title: "Customer",
			// TODO: fetch options from API
			options: [
				{ key: "", text: "All" },
				{ key: "PWC Sweden", text: "PWC Sweden" },
				{ key: "Njuta", text: "Njuta" },
			],
		},
		orderStatuses: {
			type: "switch",
			title: "Status",
			options: [
				{ key: STATUS_TODO, text: "To do" },
				{ key: STATUS_DONE, text: "Done" },
				{ key: STATUS_CANCELED, text: "Canceled" },
			],
		}
	};

	const FILTER_DEFAULTS = {
		// searchText: "",
		orderBy: "created",
		orderCustomers: "",
		orderStatuses: "unhandled,confirmed,inProgress",
	};

	const listState = useListState({
		listKey: DATASTORE,
		fetchData: API.fetchOrders,
		filterDefaults: FILTER_DEFAULTS,
		transformFilters,
	});

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={TEXT_HEADING}
			filterConfig={filterConfig}
			collapseFiltersDefault={false}
		>
			<Order />
		</GenericListApp>
	);
}

@decoratorUIActions(itemActions)
export default class OrdersApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

export function transformFilters(filters) {
	const transformed = { ...filters };
	
	switch (filters.orderStatuses) {
		case STATUS_TODO:
			transformed.orderBy = "requesteddeliverydate";
			break;
		case STATUS_DONE:
			transformed.orderBy = "completiondate";
			break;
		case STATUS_CANCELED:
			transformed.orderBy = "created";
			break;
	}

	return transformed;
}
