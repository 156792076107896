import React from 'react'

import Item from '../../../../components/cms/item'
import Modals from '../../modals/modals'

import * as CMSAPI from '../../../../apis/cms'
import useList from '../../../../core/hooks/useList'
import GenericListApp from '../../../../components/genericApp/listApp'
import { useCMSConfig } from '../../shared/config'

import '../../events/app.css'
import { decoratorUIActions } from '../../../../core/decorators/uiActions'
import listItemActions from './listItemActions'
import useEditorNavigation from '../../../../core/hooks/useEditorNavigation'
import useCMSTemplate from '../../../../core/queries/cms/useCMSTemplate'

const MODULE = "eventplanner";
const DATASTORE = "dynamic";
const TEXT_BACK_BUTTON = "Administration";
const TEXT_EMPTY = "No items? It's about time to create some then!";
const TEXT_CREATE = "Create";
const ICON_CREATE = "add";

function ListApp(props) {
	const appKey = props.params.appKey;
    const app = findApp(appKey, props.cmsConfig);

    const _module = app?.module ?? "eventplanner";
    const _entity = app?.entity ?? appKey;
    const template = useCMSTemplate(_entity, _module);
    
    const filterConfig = app?.filters ?? {};
    const filterDefaults = getFilterDefaults(_entity, _module, app?.filterDefaults, props.location);

	const listState = useList({
		listKey: _entity,
		fetchData: CMSAPI.fetchCMSEntities,
		fetchSingle: CMSAPI.fetchCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		entity: _entity,
        module: _module,
		updateDataExtraParams: { _entity, _module },
		filterDefaults: filterDefaults,
	});

    useEditorNavigation(listState);

    const [modalData, setModalData] = React.useState(null);
	const closeModal = React.useCallback(
		() => setModalData(null),
		[setModalData]
	);

	return (
        <React.Fragment>
            <GenericListApp
                {...props}
                module={MODULE}
                datastore={DATASTORE}
                state={listState}
                textHeading={app?.displayName}
                textBackButton={TEXT_BACK_BUTTON}
                textEmpty={TEXT_EMPTY}
                textCreate={TEXT_CREATE}
                iconCreate={ICON_CREATE}
                filterConfig={filterConfig}
                pathnameCreate={`eventplanner/admin/${appKey}/create`}
                collapseFiltersDefault={false}
                padding={false}
                virtualize={true}
            >
                <Item
                    template={template.data}
                    onChange={listState.onChange}
                    openModal={setModalData}
                    module={_module}
                    collection={template?.data?.collection}
                />
            </GenericListApp>
            <Modals
                modalData={modalData}
                onClose={closeModal}
            />
        </React.Fragment>
	);
}

// useList will break if config is not loaded from the start so we don't render ListApp (which uses useList) until the config is loaded
function WrapperForConfig(props) {
    const cmsConfig = useCMSConfig();
    if (!cmsConfig) {
        return null;
    }

    return <ListApp {...props} cmsConfig={cmsConfig} />;
}

@decoratorUIActions(listItemActions)
export default class DynamicApp extends React.Component {
	render() {
        return <WrapperForConfig {...this.props} />;
	}
}

function getFilterDefaults(_entity, _module, defaults, location) {
    const idFromQuery = location?.query?.id?.length >= 0 ? location.query.id : null;

    return {
        _entity,
        _module,
		pageSize: 30,
		id: idFromQuery,
        ...defaults
    };
}

export function findApp(appKey, config) {
    for (let block of (config?.blocks ?? [])) {
        for (let app of block.apps) {
            if (app.key === appKey) {
                return app;
            }
        }
    }

    return null;
}