import React from 'react'

import Item from '../../../components/cms/item'

import * as CMSAPI from '../../../apis/cms'
import useList from '../../../core/hooks/useList'
import GenericListApp from '../../../components/genericApp/listApp'
import { getItems } from '../../../components/genericApp/utils'

import useCMSTemplate from '../../../core/queries/cms/useCMSTemplate'

import './app.css'

const MODULE = "traffic";
const DATASTORE = "cmsprograms";
const TEXT_HEADING = "Programs";
const TEXT_BACK_BUTTON = null;

function ListApp(props) {
	const _entity = "programs";
	const _module = "traffic";
	const api = "trafficc8";
	const template = useCMSTemplate(_entity, _module, true, api);

	const filterConfig = {
		searchText: {
			type: "search",
			alwaysVisible: true,
		},
		type: {
			type: "switch",
			title: "Type",
			options: [
				{ key: "single|episode", text: "All" },
				{ key: "single", text: "Single" },
				{ key: "episode", text: "Episode" },
			],
			alwaysVisible: true,
		},
	};

	const FILTER_DEFAULTS = {
		_entity,
		_module,
		_attributesToSearch: ["id", "displayname(like)", "originaltitle(like)"],
		api,
		pageSize: 30,
		orderBy: "displayname",
		searchText: "",
		type: "single|episode",
	};

	const originalSortKeys = React.useRef({});
	const originalGroupKeys = React.useRef({});
	const clearKeys = React.useCallback(() => {
		originalSortKeys.current = {};
		originalGroupKeys.current = {};
	}, []);

	const listState = useList({
		listKey: DATASTORE + _entity,
		fetchData: CMSAPI.fetchCMSEntities,
		fetchSingle: CMSAPI.fetchCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		entity: _entity,
		updateDataExtraParams: { _entity },
		filterDefaults: FILTER_DEFAULTS,
		filterConfig: filterConfig,
		onFilterChange: clearKeys,
	});

	const items = getItems(listState.data);

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={TEXT_HEADING}
			textBackButton={TEXT_BACK_BUTTON}
			filterConfig={filterConfig}
			collapseFiltersDefault={true}
			groupItemsWhenSearching={true}
			padding={false}
			virtualize={false}
			items={items}
		>
			<Item
				template={template.data}
				onChange={listState.onChange}
				refreshItem={listState.refreshItem}
			/>
		</GenericListApp>
	);
}

export default class CMSProgramsApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}
