import alt from '../../core/services/alt'
import Actions from './actions'

class SchedulesStore {
	constructor() {
		// Schedules
		this.schedules = [];
		this.latestRequestTime = null;
		this.isLoading = false;

		this.selectedDates = initSelectedDates();
		this.selectedDays = [7]; // 7 is All days (0=Sunday, 1=Monday, .., 6 Saturday)

		// Schedule
		this.scheduleList = [];
		this.approveFailed = null;
		this.scheduleListIsLoading = false;
		this.hasSelectedItems = false;
		this.approveIsLoading = false;

		// Channels
		this.channels = [];
		this.channelsIsLoading = false;

		// Filters
		this.filters = {
			channel: "",
			status: "UnconfirmedChanges",
			showOld: false,
			pageSize: 50,
		};

		this.bindListeners({
			onRequestFailed: Actions.REQUEST_FAILED,

			// Schedules
			onFetchSchedules: Actions.FETCH_SCHEDULES,
			onSchedulesUpdated: Actions.SCHEDULES_UPDATED,

			// Schedule
			onFetchSchedule: Actions.FETCH_SCHEDULE,
			onScheduleUpdated: Actions.SCHEDULE_UPDATED,
			onApprove: [Actions.APPROVE_SCHEDULE, Actions.APPROVE_MANY],
			onSelectItem: Actions.SELECT_ITEM,
			onSelectAll: Actions.SELECT_ALL,
			onBroadcastUpdated: Actions.BROADCAST_UPDATED,

			// Channels
			onFetchChannels: Actions.FETCH_CHANNELS,
			onChannelsUpdated: Actions.CHANNELS_UPDATED,

			// Filters
			onFilterChannel: Actions.SET_CHANNEL_FILTER,
			onNavDate: Actions.NAV_DATE,
			onSetDays: Actions.SET_DAYS,

			// Misc
			unMountSchedule: Actions.UNMOUNT_SCHEDULE,
		});
	}

	onRequestFailed() {
		this.isLoading = false;
		this.channelsIsLoading = false;
	}

	// Schedules
	onFetchSchedules() {
		this.isLoading = true;
		this.latestRequestTime = Date.now();
	}

	onSchedulesUpdated({ items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime }) {
		this.isLoading = false;
		if (this.latestRequestTime > requestTime) {
			console.log("Ignoring result with %s items since there have been newer requests.", numberOfItems);
		} else {
			this.schedules = appendToExistingItems ? this.schedules.concat(items) : items;
			this.nextPageUrl = nextPageUrl;
			this.numberOfItems = numberOfItems;
			this.isLoading = false;
		}
	}

	// Schedule
	onFetchSchedule() {
		this.scheduleListIsLoading = true;
	}

	onScheduleUpdated({ data, approveFailed }) {
		this.scheduleListIsLoading = false;
		this.scheduleList = data.map(schedule => {
			const mergedBroadcasts = schedule.broadcasts.reduce((acc, item) => {
				if (item.partOfBroadcastId) {
					const parentItem = acc.find(i => i.id === item.partOfBroadcastId);
					if (parentItem) {
						parentItem.children = [...(parentItem.children || []), item];
					} else {
						acc.push(item);
					}
				} else {
					acc.push(item);
				}
				return acc;
			}, []);	
			return { ...schedule, broadcasts: mergedBroadcasts };
		});

		// If the approve fails, show an error
		this.approveFailed = approveFailed;
		this.approveIsLoading = false;
	}

	onApprove() {
		this.approveIsLoading = true;
	}

	onSelectItem(id) {
		if (this.scheduleList.length) {
			this.hasSelectedItems = false;
			this.scheduleList.forEach(schedule => {
				schedule.broadcasts.forEach(change => {
					change.selected = change.id === id ? !change.selected : change.selected;
					// Set selected on children when selecting parent
					change.children?.forEach(child => {
						child.selected = child.id === id || change.id === id ? !child.selected : child.selected;
					});
				});
				if (schedule.broadcasts.some(change => change.selected || change?.children?.some(child => child.selected))) {
					this.hasSelectedItems = true;
				}
			});
		}
	}

	onSelectAll() {
		if (!this.scheduleList.length) {
			return;
		}

		const allItemsSelected = this.scheduleList.every(schedule => schedule.broadcasts.every(change => change.selected || change.status === "Confirmed"));
		if (allItemsSelected) {
			this.scheduleList.forEach(schedule => {
				schedule.broadcasts.forEach(change => {
					change.selected = false;
					change.children?.forEach(child => child.selected = false);
				});
			});
			this.hasSelectedItems = false;
		} else {
			this.scheduleList.forEach(schedule => {
				schedule.broadcasts.forEach(change => {
					change.selected = change.status !== "Confirmed";
					change.children?.forEach(child => child.selected = child.status !== "Confirmed");
				});
			});
			this.hasSelectedItems = true;
		}
	}

	onBroadcastUpdated({ broadcast, newBroadcastStatusForChildren }) {
		this.scheduleList.forEach(schedule => {
			schedule.broadcasts.forEach(change => {
				if (change.id === broadcast.id) {
					change.children?.forEach(child => {
						child.broadcastStatus = newBroadcastStatusForChildren;
					});
				}
			});
		});
	}

	// Channels
	onFetchChannels() {
		this.channelsIsLoading = true;
	}

	onChannelsUpdated(items) {
		this.channels = items;
		// this.channels.unshift({ key: "", id: -1, text: "All" });
		this.channelsIsLoading = false;
	}

	// Filters
	onFilterChannel({ channel }) {
		this.filters.channel = channel;
	}

	onNavDate(selectedDates) {
		if (selectedDates) {
			this.selectedDates = selectedDates;
		} else {
			this.selectedDates = initSelectedDates();
		}
	}

	onSetDays(selectedDays) {
		this.selectedDays = selectedDays;
	}

	// Misc
	unMountSchedule() {
		this.scheduleList = [];
		this.hasSelectedItems = false;
	}
}

export default alt.createStore(SchedulesStore, "[Schedules]Store");

function initSelectedDates() {
	const fromDate = Date.now(); // from = today
	let untilDate = new Date();
	untilDate.setDate(untilDate.getDate() + 7); // until = 1 week from now
	return [fromDate, untilDate];
}