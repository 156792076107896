import React from 'react'

import ScheduleRelease from './schedulerelease'
import scheduleReleaseActions from './listScheduleReleaseActions'

import * as SchedulesAPI from '../../../apis/schedules'
import useList from '../../../core/hooks/useList'
import GenericListApp from '../../../components/genericApp/listApp'
import { decoratorUIActions } from '../../../core/decorators/uiActions'
import appConfig from 'config'
import useSchedulesChannelBundles from '../../../core/queries/schedules/useSchedulesChannelBundles'

const MODULE = "schedules"
const DATASTORE = "scheduleReleases"
const TEXT_HEADING = "Schedule Releases"
const TEXT_BACK_BUTTON = "Administration"
const TEXT_EMPTY = "No schedule releases? It's about time to create some then!"
const TEXT_CREATE = "Create a schedule release"
const ICON_CREATE = "add"
const PATHNAME_CREATE = "schedules/releases/create"

function ListApp(props) {

	const filterDefaults = {
		searchText: "",
	};

	const listState = useList({
		listKey: DATASTORE,
		fetchData: SchedulesAPI.fetchScheduleReleases,
		filterDefaults: filterDefaults,
	});

	const bundleNames = appConfig.features.scheduleReleasesBundleFilter ?? "TV4_ScheduleRelease,TV4Plus_ScheduleRelease";
	const { data: allBundles } = useSchedulesChannelBundles({
		filter: { bundleNames },
	});

	return (
		<GenericListApp
			{...props}
			module={MODULE}
			datastore={DATASTORE}
			state={listState}
			textHeading={TEXT_HEADING}
			textBackButton={TEXT_BACK_BUTTON}
			textEmpty={TEXT_EMPTY}
			textCreate={TEXT_CREATE}
			iconCreate={ICON_CREATE}
			filterConfig={{}}
			pathnameCreate={appConfig.features.scheduleReleasesHideCreate ? null : PATHNAME_CREATE} // TODO: Remove after #HERO launch
			// collapseFiltersDefault={false}
		>
			<ScheduleRelease
				allBundles={allBundles?.items ?? []}
			/>
		</GenericListApp>
	);
}

@decoratorUIActions(scheduleReleaseActions)
export default class ScheduleReleasesApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}