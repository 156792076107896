import React from 'react'
import appConfig from 'config'
import cloneDeep from 'lodash/cloneDeep'

import Editor, { parseUi } from '../../../../components/editor/'
import StarUpload from '../../../../components/ui/editorWidgets/starUpload'
import Links from '../../../../components/ui/editorFields/linksNew'
import StarLibrary from '../../../star2/containereditor-metadata/library'
import CustomDragLayer from '../../../../components/list/dragLayer'
import withDnDContext from '../../../../core/services/withDnDContext'

import { hasAccessToPath } from '../../../../core/services/auth'
import * as API from '../../../../apis/'
import SelectionsStore from '../../store'

import itemSchema from './itemSchema'
import itemUI from './itemUI'
import broadcastSchema from './broadcastSchema'
import broadcastUI from './broadcastUI'
import announcementSchema from './announcementSchema'
import announcementUI from './announcementUI'

import { Regions } from '../../../../core/constants'

import './itemeditor.css'

class ItemEditor extends React.Component {

	state = {
		expandStarSection: false,
		selectionsConfiguration: null,
	}

	toggleStar = () => {
		this.setState(state => ({ expandStarSection: !state.expandStarSection }));
	}

	componentDidMount() {
		const configuration = SelectionsStore.getState().item.selectionsConfiguration;
		if (configuration) {
			this.setState({ selectionsConfiguration: configuration });
		} else {
			API.selections.fetchConfiguration().then(config => this.setState({ selectionsConfiguration: config }));
		}
	}

	render() {
		let className = "c6-selections-itemeditor-wrapper";
		if (appConfig.features.selectionsItemEditorSTARAddon) className += " star-addon-enabled";
		if (this.state.expandStarSection) className += " expand-star"

		return (
			<div className={className}>
				<Editor
					layout="grid"
					api="selections"
					entity="item"
					getCommands={getCommands}
					getSchema={getSchema.bind(this, this.state.selectionsConfiguration)}
					getUiSchema={getUiSchema.bind(this, this.state.selectionsConfiguration)}
					loadPayloadTransform={loadPayloadTransform.bind(this, this.state.selectionsConfiguration)}
					savePayloadTransform={savePayloadTransform}
					hasEditAccess={hasAccessToPath(this.props.routes, "editor")}
					customWidgets={{ upload: StarUpload }}
					customFields={{ links: Links }}
					{...this.props}
				/>
				{appConfig.features.selectionsItemEditorSTARAddon && (
					<React.Fragment>
						<div className="star">
							<div
								className="star-toggler"
								onClick={this.toggleStar}
							>
								<p>Add an image or video from STAR</p>
								<p className={this.state.expandStarSection ? "icon-expand_more" : "icon-expand_less"}></p>
							</div>
							<StarLibrary
								container={{}}
								defaultIsSearching={true}
							/>
						</div>
						<CustomDragLayer />
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default withDnDContext(ItemEditor);

function getCommands({ entity, location, route, params }) {
	return {
		fetchItem: ({ id }) => API.selections.fetchItem(id),
		createItem: (payload) => {
			return new Promise((resolve, reject) => {
				const listId = params.listId;
				payload.inputSource = "manual";
				payload.provider = "Undefined";
				payload.type = location.query?.itemType ?? "editorial";
				payload.name = payload.displayName;
				// 1: Create item
				let item;
				API.selections.insertItem(payload)
					.then(response => {
						item = response;
						const itemPayload = {
							date: location.query.date,
							id: item.id,
							position: { ordinal: 0 },
						};

						// 2: Add item to list
						return API.selections.updateListItem(listId, itemPayload);
					}, reject)
					.then(response => {
						// 3: Return item to editor
						resolve(item);
					}, reject)
					.catch(reject);
			});
		},
		updateItem: ({ id }, payload) => {
			const listId = params.listId;
			payload.inputSource = "manual"; // Set InputSource="Manual" when saving items so that a new item is created when making manual changes. Requested by HEL & JOL 2019-11-08
			payload.date = location.query.date;
			return API.selections.updateItem(id, listId, payload);
		}
	}
}

function getSchema(selectionsConfiguration, model, isNew, location, route, params) {
	const channelsConfig = selectionsConfiguration?.itemEditorChannels;
	if (!channelsConfig) {
		return {};
	}

	const serviceId = params.serviceId ?? location.query?.serviceId;
	const regions = getRegions(selectionsConfiguration, serviceId);
	if (!model.localizations && regions?.length) {
		model.localizations = regions.map(r => ({ version: { id: r.id, name: r.languageDisplayName } }));
	}

	let schema;
	let type = location.query?.itemType ?? model?.type;
	if (["invitation", "program-change", "other-news"].includes(model?.itemCategory)) {
		type = "announcement";
	}
	switch (type) {
		case "announcement":
			schema = announcementSchema;
			break;
		case "broadcast":
			schema = broadcastSchema;
			break;
		default:
			schema = itemSchema;
			break;
	}

	if (schema.properties.channel) {
		schema.properties.channel.oneOf = channelsConfig?.map(c => ({
			const: c.key,
			title: c.text,
		})) ?? [{ const: "", title: "" }];
	}

	const assetsConfig = selectionsConfiguration?.itemEditorAssets;
	if (appConfig.features.selectionsItemEditorSTARAddon && assetsConfig?.length) {
		schema.properties.assets.items = assetsConfig.map(config => ({
			type: "object",
			title: "",
			properties: {
				guid: {
					title: config.title,
					type: "string",
				},
				type: {
					type: "string",
					default: config.type === "Clip" ? "Video" : config.type,
				}
			}
		}));

		schema.properties.assets.default = assetsConfig.map(config => ({ type: config.type }));
	} else {
		schema.properties.assets.default = [{ type: "Image" }];
	}

	// HACK for partner service
	if (parseInt(params.serviceId) === 4) {
		const schemaCopy = cloneDeep(schema);
		delete schemaCopy.properties.start;
		delete schemaCopy.properties.end;
		delete schemaCopy.properties.channel;
		return schemaCopy;
	}
	
	return schema;
}

function getUiSchema(selectionsConfiguration, model, isNew, location, route, params) {
	const channelsConfig = selectionsConfiguration?.itemEditorChannels;
	if (!channelsConfig) {
		return {};
	}


	let ui;
	let type = location.query?.itemType ?? model?.type;
	if (["invitation", "program-change", "other-news"].includes(model?.itemCategory)) {
		type = "announcement";
	}
	switch (type) {
		case "announcement":
			ui = announcementUI;
			break;
		case "broadcast":
			ui = broadcastUI;
			break;
		default:
			ui = itemUI;
			break;
	}

	const assetsConfig = selectionsConfiguration?.itemEditorAssets;
	if (appConfig.features.selectionsItemEditorSTARAddon && assetsConfig?.length) {
		ui.assets.items = assetsConfig.map(config => ({
			guid: {
				"ui:widget": "upload",
				"ui:options": {
					...config,
				}
			},
			type: { "ui:widget": "hidden" }
		}));
	}

	// HACK for partner service
	if (parseInt(params.serviceId) === 4) {
		const schemaCopy = cloneDeep(ui);
		if (parseInt(params.listId) === 3798) { // downloads
			schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Portrait").guid["ui:widget"] = "hidden";
			schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Clip").guid["ui:widget"] = "hidden";
			schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Landscape").guid["ui:options"].assetCategoryId = 71;
			schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Landscape").guid["ui:options"].cropTypeId = 3;
		}
		if (
			parseInt(params.listId) === 3797 // current films and series
			|| parseInt(params.listId) === 3804 // sport teasers
			|| parseInt(params.listId) === 3795 // teasers
		) {
			schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Portrait").guid["ui:widget"] = "hidden";
			schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Clip").guid["ui:widget"] = "hidden";
			schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Document").guid["ui:widget"] = "hidden";

			schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Landscape").guid["ui:options"].assetCategoryId = 71;
			schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Landscape").guid["ui:options"].cropTypeId = 3;
		}
		return parseUi(schemaCopy, API);
	}

	if (type === "announcement") {
		const schemaCopy = cloneDeep(ui);
		schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Portrait").guid["ui:widget"] = "hidden";
		schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Clip").guid["ui:widget"] = "hidden";
		schemaCopy.assets.items.find(a => a.guid["ui:options"].title === "Document").guid["ui:widget"] = "hidden";
		return parseUi(schemaCopy, API);
	}

	return parseUi(ui, API);
}


// Only display the configured locale versions
function loadPayloadTransform(selectionsConfiguration, { model, entity, location, route, params }) {
	if (!model.links?.length) {
		model.links = [{ type: "Undefined" }];
	}
	
	const serviceId = params.serviceId ?? location.query?.serviceId;
	const regions = getRegions(selectionsConfiguration, serviceId);
	if (!regions?.length) {
		return model;
	}

	const primaryAsset = model.assets?.find(a => a.type === "Image") ?? { type: "Image" };
	const assets = [primaryAsset];
	if (appConfig.features.selectionsItemEditorSTARAddon) {
		const clipAsset = model.assets?.find(a => a.type === "Video") ?? { type: "Video" };
		let coverAsset = { type: "Image" };
		model.localizations?.forEach(l => {
			l.assets?.forEach(asset => {
				if (asset.type === "Image" && asset.guid) {
					coverAsset = asset;
				}
			})
		});
		assets.push(coverAsset);
		assets.push(clipAsset);

		const documentAsset = model.assets?.find(a => a.type === "Document") ?? { type: "Document" };
		assets.push(documentAsset);
	}

	return {
		...model,
		assets,
		localizations: model.localizations.filter(l => {
			return regions.some(r => r.id === l.version.id);
		}),
	};
}

function savePayloadTransform({ formData, location, ...rest }) {
	const [primaryAsset, coverAsset, clipAsset, documentAsset] = formData.assets;
	let localizations = formData.localizations;
	if (coverAsset?.guid) {
		localizations.forEach(l => l.assets = [coverAsset]);
	}

	return {
		...rest,
		location,
		queryString: location.search,
		formData: {
			...formData,
			assets: [primaryAsset, clipAsset, documentAsset],
			links: formData.links?.filter(l => l.type !== "Undefined"),
			localizations,
		},
	};
}

function getRegions(selectionsConfiguration, serviceId) {
	const serviceRegions = selectionsConfiguration?.serviceConfigurations?.find(s => s.serviceId === parseInt(serviceId))?.regions;
	if (!serviceRegions) {
		return Regions;
	}
	
	return Regions?.filter(r => serviceRegions?.includes(r.country));
}