import React from 'react'
import moment from 'moment'

import ContentItem, { Content } from '../../../components/list/contentItem'
import Label from '../../../components/ui/label'

import { copyTextToClipboard } from '../../../utils/misc'

import '../../../components/listItems/standard.css'

const Order = (props) => {

	const {
		name,
		created,
		configuration,
		customer,
		createdBy,
		orderStatus,
	} = props;

	return (
		<ContentItem data={props}>
			<Content>
				<h1>
					<span
						className="copy-on-click"
						onClick={(e) => copyTextToClipboard(name, "Copied order name to your clipboard!", e)}
						title="Click here to copy order name to your clipboard"	
					>
						{name}
					</span>
				</h1>
				<p>
					Created {formatOrderCreated(created)}
				</p>
			</Content>
			<Content>
				<h1>{customer}</h1>
				<p>
					<span
						className="copy-on-click"
						onClick={(e) => copyTextToClipboard(createdBy, "Copied username to your clipboard!", e)}
						title="Click here to copy username to your clipboard"	
					>
						{createdBy}
					</span>
				</p>
			</Content>
			<Content>
				<Label status={getLabelStatus(orderStatus)}>{formatOrderStatus(orderStatus)}</Label>
				{configuration.requestedDeliveryDate && <p>{formatRequestedDeliveryDate(configuration.requestedDeliveryDate)}</p>}
			</Content>
		</ContentItem>
	);
};

export default Order;

function formatOrderCreated(created) {
	const isThisYear = moment(created).isSame(moment(), "year");
	if (isThisYear) {
		return moment(created).format("D MMM HH:mm");
	}

    return moment(created).format("D MMM YYYY HH:mm");
}

function formatOrderStatus(status) {
	switch (status) {
		case "unhandled":
			return "Not handled";
		case "inProgress":
			return "In progress";
		default:
			return "";
	}
}

function getLabelStatus(status) {
	switch (status) {
		case "unhandled":
			return "null";
		case "inProgress":
			return "waiting";
		default:
			return "";
	}
}

function formatRequestedDeliveryDate(requestedDeliveryDate) {
	return moment(requestedDeliveryDate).calendar(null, {
		sameDay: "[Today]",
		nextDay: "[Tomorrow]",
		nextWeek: m => m.isSame(moment(), "year") ? "D MMM" : "D MMM YYYY",
		lastDay: "[Yesterday]",
		lastWeek: m => m.isSame(moment(), "year") ? "D MMM" : "D MMM YYYY",
		sameElse: m => m.isSame(moment(), "year") ? "D MMM" : "D MMM YYYY",
	});
}