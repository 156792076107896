import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import { Dialog } from '@mui/material'

import DropDown from '../../components/ui/controls/dropdown'

import { getUserAccessLevelForModule } from '../services/auth'
import { useLocalStorage } from '../hooks/useStorage'
import { useDarkMode } from '../hooks/useDarkMode'
import * as ShieldAPI from '../../apis/shield'
import { displayAlert } from '../services/alert'

import appConfig from 'config'

import './login.css'

const UserControls = (props) => {

	const {
		onLogOut,
		user,
	} = props;
	
	const fallbackLoginDetails = {
		email: user?.email ?? user?.username,
	};
	[fallbackLoginDetails.firstName, fallbackLoginDetails.lastName] = user?.name?.split(" ") ?? ["", ""];

	const [isLoading, setIsLoading] = useState(false);
	const [loginDetails, setLoginDetails] = useState(appConfig.features.unlockUserDetailsForm ? null : fallbackLoginDetails);
	const [newLoginDetails, setNewLoginDetails] = useState(appConfig.features.unlockUserDetailsForm ? null : fallbackLoginDetails);
	const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
	const [darkMode, setDarkMode] = useDarkMode();

	useEffect(
		() => {
			(async () => {
				try {
					if (!appConfig.features.unlockUserDetailsForm) {
						return;
					}
					setIsLoading(true);
					const response = await ShieldAPI.fetchLoginDetails();
					setLoginDetails(response);
					setNewLoginDetails(response);
				} catch {
					displayAlert("error", "Failed to load your account details. If this problem persists, please contact support@junefirst.tv.");
				} finally {
					setIsLoading(false);
				}
			})();
		},
		[]
	);

	const saveLoginDetails = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const response = await ShieldAPI.updateLoginDetails(newLoginDetails);
			setLoginDetails(response);
			setNewLoginDetails(response);
			
			displayAlert("success", "Successfully saved your changes.");
		} catch {
			displayAlert("error", "Failed to save your changes. If this problem persists, please contact support@junefirst.tv.");
		} finally {
			setIsLoading(false);
		}
	};

	const loginDetailsChanged = newLoginDetails?.firstName !== loginDetails?.firstName
		|| newLoginDetails?.lastName !== loginDetails?.lastName
		|| newLoginDetails?.email !== loginDetails?.email;

	const isSysadmin = user?.username === "sysadmin";
	const cmsAccessLevel = getUserAccessLevelForModule("eventplanner");
	const showCmsStageSelect = isSysadmin || cmsAccessLevel === "admin";

	const heading = loginDetails
		? `${loginDetails?.firstName} ${loginDetails?.lastName}`
		: user?.name;
		
	return (
		<div className="c6-authentication-form user-controls">
			<header>
				<div className="icon-person" />
				<h1>{heading}</h1>
			</header>
			<div className="form-body">
				<div className="account-settings" disabled={!appConfig.features.unlockUserDetailsForm}>
					<h2>Account</h2>
					<div>
						<TextField
							variant="standard"
							color="primary"
							label="First name"
							value={newLoginDetails?.firstName ?? (isLoading ? "Loading..." : "")}
							onChange={(e) => {
								setNewLoginDetails({
									...newLoginDetails,
									firstName: e.target.value,
								});
							}}
							disabled={isLoading}
							fullWidth
							className="c6-authentication-input"
						/>
					</div>
					<div>
						<TextField
							variant="standard"
							color="primary"
							label="Last name"
							value={newLoginDetails?.lastName ?? (isLoading ? "Loading..." : "")}
							onChange={(e) => {
								setNewLoginDetails({
									...newLoginDetails,
									lastName: e.target.value,
								});
							}}
							disabled={isLoading}
							fullWidth
							className="c6-authentication-input"
						/>
					</div>
					<div>
						<TextField
							variant="standard"
							color="primary"
							label="E-mail"
							value={newLoginDetails?.email ?? (isLoading ? "Loading..." : "")}
							disabled={appConfig.features.unlockUserDetailsForm}
							fullWidth
							className="c6-authentication-input"
							helperText={appConfig.features.unlockUserDetailsForm ? "To update your e-mail address, please contact support@junefirst.tv" : null}
						/>
					</div>
					{appConfig.features.unlockUserDetailsForm && (
						<div>
							<TextField
								variant="standard"
								type="password"
								color="primary"
								label="Password"
								value="***************"
								onClick={() => {
									setPasswordDialogOpen(true);
								}}
								readOnly
								style={{ cursor: "pointer" }}
								InputLabelProps={{
									style: { cursor: "pointer" },
								}}
								InputProps={{
									style: { cursor: "pointer" },
								}}
								inputProps={{
									style: { cursor: "pointer" },
								}}
								fullWidth
								className="c6-authentication-input"
							/>
						</div>
					)}
					<Dialog
						open={passwordDialogOpen}
						onClose={() => setPasswordDialogOpen(false)}
						fullWidth
						maxWidth="xs"
					>
						<UpdatePasswordForm
							onClose={() => setPasswordDialogOpen(false)}
						/>
					</Dialog>
					<div>
						<Button
							type="button"
							variant="outlined"
							onClick={saveLoginDetails}
							color="primary"
							style={{
								transition: "opacity 0.3s ease",
								opacity: loginDetailsChanged ? 1 : 0,
							}}
							disabled={!loginDetailsChanged || isLoading}
						>
							Save account changes
						</Button>
					</div>
				</div>
				<div className="preferences">
					<h2>Preferences</h2>
					{appConfig.features.enableDarkModeSwitch && (
						<div>
							<span>Dark mode</span>
							<Switch
								checked={darkMode}
								onChange={(e) => setDarkMode(e.target.checked)}
							/>
						</div>
					)}
					{appConfig.features.newCometNavigation && (
						<div>
							<span>Left side menu</span>
							<Switch
								checked={props.newNavigation}
								onChange={() => props.onToggleNewNavigation()}
							/>
						</div>
					)}
					{/* eslint-disable-next-line no-undef */}
					{__BUILD_PRODUCT__ === "comet" && (
						<div>
							<span>Uploader popup</span>
							<Switch
								checked={!props.hideGlobalUploader}
								onChange={() => props.onToggleGlobalUploader()}
							/>
						</div>
					)}
					{showCmsStageSelect && (
						<div>
							<span>CMS stage</span>
							<CmsStageSelect />
						</div>
					)}
				</div>
			</div>
			<div className="form-bottom">
				<Button
					type="button"
					variant="contained"
					onClick={onLogOut}
					color="primary"
					startIcon={<span className="icon-logout" />}
				>
					Log out
				</Button>
			</div>
		</div>
	);
}

export default UserControls;

const stages = [
	{ key: "null", text: "Not selected" },
	{ key: "private", text: "Private" },
	{ key: "preview", text: "Preview" },
	{ key: "acceptance", text: "Acceptance" },
	{ key: "released", text: "Released" },
];

function CmsStageSelect() {
	const [cmsStage, setCmsStage] = useLocalStorage("x-cms-stage", "null", true);

	return (
		<DropDown
			onChange={e => setCmsStage(e.target.value)}
			states={stages}
			currentState={cmsStage}
		/>
	);
}

function UpdatePasswordForm(props) {
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const savePassword = async () => {
		setIsLoading(true);

		try {
			await ShieldAPI.updateLoginPassword({
				password,
				newPassword,
				confirmPassword,
			});
			displayAlert("success", "Successfully updated your password.");
			props.onClose();
		} catch (error) {
			if (error?.message?.includes("does not match")) {
				displayAlert("error", "The new password and confirmation do not match.");
			} else {
				displayAlert("error", "Failed to update your password. If this problem persists, please contact support@junefirst.tv.");
			}
		} finally {
			setIsLoading(false);
		}
	};

	const enableSave = password?.length && newPassword?.length && confirmPassword?.length;

	return (
		<div className="c6-authentication-form">
			<header>
				<h1>Update password</h1>
			</header>
			<div className="form-body account-settings">
				<div>
					<TextField
						variant="standard"
						type="password"
						color="primary"
						label="Current password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						fullWidth
						className="c6-authentication-input"
					/>
				</div>
				<div>
					<TextField
						variant="standard"
						type="password"
						color="primary"
						label="New password"
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
						fullWidth
						className="c6-authentication-input"
					/>
				</div>
				<div>
					<TextField
						variant="standard"
						type="password"
						color="primary"
						label="Confirm new password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						fullWidth
						className="c6-authentication-input"
					/>
				</div>
				<div>
					<Button
						type="button"
						variant="outlined"
						onClick={savePassword}
						color="primary"
						style={{
							transition: "opacity 0.3s ease",
							display: "block",
							margin: "10px auto 20px auto",
						}}
						disabled={!enableSave || isLoading}
					>
						Save password
					</Button>
				</div>
			</div>
		</div>
	);
}