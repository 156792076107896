import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import { Swedish } from "flatpickr/dist/l10n/sv.js"
import moment from 'moment'

import { getRouteName } from '../../../components/comet'

import App from '../../../components/app'
import { Info } from '../../../components/comet'
import Button from '../../../components/ui/controls/button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import ChannelList from './channelList'

import Store from '../store'
import Actions from '../actions'

import './schedules.css'
import '../../../components/ui/controls/pickers/picker.css'
import appConfig from 'config'
import Const from '../../../core/constants'

const MODULE = "schedules";
const DATASTORE = "schedules";
const TEXT_HEADING = "Linear schedule ingest";

class Schedules extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...Store.getState(),
		};
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if (!this.state.channels.length) {
			Actions.fetchChannels();
		}
		// Actions.fetchSchedules(this.state.filters);
	}

	componentWillUnmount() {
		Store.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	loadMore = () => {
		if (this.state.nextPageUrl) {
			Actions.pageSchedules(this.state.nextPageUrl);
		}
	}

	handleSelectChannel = channel => {
		Actions.setChannelFilter(appConfig.features.schedulesUseChannelNameInsteadOfReference ? channel.providerName : channel.reference);
		// Actions.fetchSchedules(this.state.filters);
	}

	handleFetchClick = () => {
		const { selectedDates, filters, channels, selectedDays } = this.state;
		const selectedChannel = filters.channel;
		Actions.fetchFromLouise(selectedDates, selectedChannel, selectedDays);
	}

	handlePickerClose = selectedDates => {
		Actions.navDate(selectedDates.length === 2 ? selectedDates : null);
	}

	onDaysChanged = (e, index, value) => {
		Actions.setDays(getSelectedDays(e.target.value));
	}

	render() {
		const {
			isLoading,
			channelsIsLoading,
			channels,
			filters,
			selectedDates,
			selectedDays
		} = this.state;

		const channel = channels?.find(c => appConfig.features.schedulesUseChannelNameInsteadOfReference ? c.providerName === filters.channel : c.reference === filters.channel);

		return (
			<App
				name={`c6-${MODULE}-${DATASTORE} c6-list`}
				isLoading={isLoading || channelsIsLoading}
			>
				<h1>
					{TEXT_HEADING}
				</h1>
				<div className="channel-schedules-wrapper">
					<div className="channel-picker">
						<ChannelList
							title="CHANNELS"
							channels={channels}
							filters={filters}
							onSelectChannel={this.handleSelectChannel}
						/>
					</div>
					<div className="filters">
						<div>
							<Flatpickr
								options={{
									altInput: true,
									altFormat: "j M Y",
									locale: Swedish,
									mode: "range",
									minDate: moment().subtract(1, 'days').format(Const.DATE_FORMAT),
									weekNumbers: true,
								}}
								value={selectedDates}
								onChange={Actions.navDate}
								onClose={this.handlePickerClose}
							/>
							<Select
								className="c6-mui-select"
								value={getSelectedDays(selectedDays) ?? ""}
								onChange={this.onDaysChanged}
								multiple={true}
								variant="standard"
								MenuProps={{ className: "c6-mui-select-menu" }}
								sx={{ minWidth: "200px" }}
							>
								{getDaysOfTheWeekMenuItem()}
							</Select>
							<Button
								title={channel?.provider ? `Fetch changes from ${channel.provider}` : "Fetch changes"}
								type="sync"
								disabled={!filters.channel}
								onClick={this.handleFetchClick}
							/>
						</div>
					</div>
				</div>
				<Info>{getInfo(this.props.location, this.state)}</Info>
			</App>
		);
	}
}

Schedules.propTypes = {
	location: PropTypes.shape().isRequired,
};

export default Schedules;

// Helpers
function getInfo(location, { numberOfItems, ...rest }) {
	const ds = rest[DATASTORE];
	const items = ds.length ? `- displaying ${ds.length} of ${numberOfItems}` : '';

	return `${getRouteName(location)} ${items}`;
}

function getSelectedDays(selectedDays) {
	if (selectedDays.length > 1) {
		if (selectedDays[selectedDays.length -1] === 7 || selectedDays.length === 7) { 
			// if last put in value is "all", that means we should remove all options, because All is All.
			return [7];
		}
		if (selectedDays.includes(7)) {
			// Remove "All" option if it's included with other values
			return selectedDays.filter(day => day !== 7);
		}
		
	} 
	
	if (selectedDays.length === 0) {
		return [7]; // Default to "All" if empty or all days are picked
	}
	return selectedDays;
}

function getDaysOfTheWeekMenuItem() {
	return [
		<MenuItem key={7} value={7}>All days</MenuItem>,
		<MenuItem key={1} value={1}>Mondays</MenuItem>,
		<MenuItem key={2} value={2}>Tuesdays</MenuItem>,
		<MenuItem key={3} value={3}>Wednesdays</MenuItem>,
		<MenuItem key={4} value={4}>Thursdays</MenuItem>,
		<MenuItem key={5} value={5}>Fridays</MenuItem>,
		<MenuItem key={6} value={6}>Saturdays</MenuItem>,
		<MenuItem key={0} value={0}>Sundays</MenuItem>,
	];
}

