import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import Status from '../../ui/status'

import './status.css'

const CMSStatus = ({
	value,
	className,
	displayName,
	tooltip,
	onChange,
	options,
}) => {

	const statusRef = React.useRef();
	const [menuOpen, setMenuOpen] = React.useState(false);

	return (
		<div className={className} title={tooltip ?? `${value}`}>
			<Status
				elRef={statusRef}
                status={`${value}`}
                onClick={options?.enum?.length ? () => setMenuOpen(true) : null}
            >
                {displayName}
            </Status>
			{options?.enum?.length && (
				<Menu
					value={value}
					onClose={() => setMenuOpen(false)}
					open={menuOpen}
					anchorEl={statusRef.current}
				>
					{options.enum.map(v => (
						<MenuItem
							key={v}
							value={v}
							onClick={() => {
								onChange(v);
								setMenuOpen(false);
							}}
						>
							{v}
						</MenuItem>
					))}
				</Menu>	
			)}
		</div>
	);
};

export default CMSStatus;