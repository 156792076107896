import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { Link } from 'react-router'

import Item from '../../../components/cms/item'
import CreateEventDialog from '../create-event-dialog'
import Modals from '../modals/modals'
import { hasAccessToPath } from '../../../core/services/auth'

import * as CMSAPI from '../../../apis/cms'
import useList, { updateItemInMatchingQueries } from '../../../core/hooks/useList'
import { useSessionStorage } from '../../../core/hooks/useStorage'
import GenericListApp from '../../../components/genericApp/listApp'
import { getItems } from '../../../components/genericApp/utils'
import Button from '../../../components/ui/controls/button'

import { transformFilters, getSeriesOptions, EVENTPLANNER_DATE_FILTER, getSettingsOptions } from '../shared/utils'
import { getValue } from '../../../core/cms/utils'
import { renderTodayOrDate } from '../../../utils/date'
import { saveBlobToDisc } from '../../../utils/save'
import { ItemActionsContext, useItemActionsState } from '../../../components/cms/itemActionsContext'
import useCMSTemplate from '../../../core/queries/cms/useCMSTemplate'
import useCMSSportSeries from '../../../core/queries/cms/useCMSSportSeries'

import './app.css'

const MODULE = "eventplanner";
const DATASTORE = "events";
const TEXT_HEADING = "Events";
const TEXT_BACK_BUTTON = null;//"Administration"
const TEXT_EMPTY = "No events? It's about time to create some then!";
const TEXT_CREATE = "Create event";
const ICON_CREATE = "add";

const PROVIDER_FILTERS = {
	"mtv": {
		_entity: "mtv-eventslist",
		resourcesLink: "/eventplanner/resources/mtv",
		resourcesApp: "resources-mtv",
	},
	"tv4": {
		_entity: "tv4-eventslist",
		resourcesLink: "/eventplanner/resources/tv4",
		resourcesApp: "resources-tv4",
	},
	"default": {
		_entity: "tv4-eventslist",
		resourcesLink: "/eventplanner/resources",
		resourcesApp: "resources",
	}
};

const FILTER_DEFAULTS = {
	pageSize: 30,
	orderBy: "ott.start asc",
	searchText: "",
	"season.series.id": "",
	"settings-and": "",
	status: "",
};

function ListApp(props) {
	const [dialogOpen, setDialogOpen] = React.useState(false);

	const [_entity, setEntity] = React.useState(PROVIDER_FILTERS[props.params?.provider]?._entity ?? PROVIDER_FILTERS["default"]._entity);
	React.useEffect(
		() => {
			setEntity(PROVIDER_FILTERS[props.params?.provider]?._entity ?? PROVIDER_FILTERS["default"]._entity);
		},
		[props.params?.provider]
	);

	const template = useCMSTemplate(_entity);
	const { data: series, refetch: refetchSeries } = useCMSSportSeries({ provider: props.params?.provider });

	const [universalEventPlannerDate, setUniversalEventPlannerDate] = useSessionStorage(EVENTPLANNER_DATE_FILTER, moment().startOf("day").format());
	const filterConfig = {
		">=ott.start": {
			type: "date",
			alwaysVisible: true,
			onChange: (newValue) => {
				// Clear stored value when selecting today
				const isToday = moment(newValue).isSame(moment(), "day");
				setUniversalEventPlannerDate(isToday ? undefined : moment(newValue).startOf("day").format());
			},
		},
		searchText: {
			type: "search",
			alwaysVisible: true,
		},
		"season.series.id": {
			type: "dropdown",
			title: "Tour/League",
			options: getSeriesOptions(series?.items, "Any tour/league"),
			alwaysVisible: true,
			filterable: true,
		},
		"settings-and": {
			type: "pick",
			title: "Settings",
			options: getSettingsOptions(props.params?.provider),
		},
		status: {
			type: "switch",
			title: "Status",
			options: [
				{ key: "", text: "Any" },
				{ key: "!reviewed", text: "For review" },
			],
		},
	};

	const filterDefaults = {
		...FILTER_DEFAULTS,
		_entity,
		">=ott.start": universalEventPlannerDate,
	};

	const originalSortKeys = React.useRef({});
	const originalGroupKeys = React.useRef({});
	const clearKeys = React.useCallback(() => {
		originalSortKeys.current = {};
		originalGroupKeys.current = {};
	}, []);

	const listState = useList({
		listKey: DATASTORE + _entity + JSON.stringify(FILTER_DEFAULTS),
		fetchData: CMSAPI.fetchCMSEntities,
		transformFilters: (filters) => transformFilters(filters, props.params?.provider),
		fetchSingle: CMSAPI.fetchCMSEntity,
		patchData: CMSAPI.updateCMSEntity,
		runAction: CMSAPI.runAction,
		entity: _entity,
		updateDataExtraParams: { _entity },
		filterDefaults,
		filterConfig: filterConfig,
		onFilterChange: clearKeys,
	});

	const [modalData, setModalData] = React.useState(null);
	const closeModal = React.useCallback(
		() => setModalData(null),
		[setModalData]
	);
	const toggleDialog = React.useCallback(
		() => setDialogOpen(!dialogOpen),
		[setDialogOpen, dialogOpen]
	);

	const client = useQueryClient();
	const onEventCreated = React.useCallback(
		items => {
			if (items?.length) {
				updateItemInMatchingQueries({ client, store: DATASTORE, items });
			} else {
				listState.refetch();
			}
		},
		[listState]
	);

	const actions = useItemActionsState({
		extraActions: [],
		_entity,
		refetch: listState.refetch,
		setModalData,
		collection: template?.data?.collection,
	});

	const items = getItems(listState.data);
	items.forEach(item => {
		if (originalSortKeys.current?.[item.id] === undefined) {
			originalSortKeys.current[item.id] = getItemSortKey(item);
		}
		if (originalGroupKeys.current?.[item.id] === undefined) {
			originalGroupKeys.current[item.id] = getItemGroupKey(item);
		}
	});

	const rightHeaderExtra = React.useMemo(
		() => {
			return (
				<React.Fragment>
					<Button
						title="Export"
						type="file-excel"
						onClick={() => downloadExcel(listState.latestFetchPayload.current)}
					/>
				</React.Fragment>
			);
		},
		[]
	);

	return (
		<ItemActionsContext.Provider value={actions}>
			<GenericListApp
				{...props}
				module={MODULE}
				datastore={DATASTORE}
				state={listState}
				textHeading={TEXT_HEADING}
				textBackButton={TEXT_BACK_BUTTON}
				textEmpty={TEXT_EMPTY}
				textCreate={TEXT_CREATE}
				iconCreate={ICON_CREATE}
				filterConfig={filterConfig}
				onCreateClick={toggleDialog}
				collapseFiltersDefault={true}
				groupItemsBy={item => groupItemsBy(item, originalGroupKeys.current)}
				getGroupTitle={(groupKey) => getGroupTitle(groupKey, PROVIDER_FILTERS[props.params?.provider]?.resourcesLink, PROVIDER_FILTERS[props.params?.provider]?.resourcesApp)}
				sortItemsBy={item => sortItemsBy(item, originalSortKeys.current)}
				groupItemsWhenSearching={true}
				padding={true}
				virtualize={false}
				rightHeaderExtra={rightHeaderExtra}
				items={items}
			>
				<Item
					template={template.data}
					onChange={listState.onChange}
					openModal={setModalData}
					refreshItem={listState.refreshItem}
				/>
			</GenericListApp>
			{dialogOpen && (
				<CreateEventDialog
					onClose={toggleDialog}
					onCreated={onEventCreated}
					onSeriesCreated={refetchSeries}
					eventRowTemplate="eventwizard"
					seriesFilters={{ provider: props.params?.provider }}
					// genreFilters={{ genreType: "sport" }}
					eventType="sport"
					eventEntity={_entity}
					importTypes={["score24", "metadata"]}
				/>
			)}
			<Modals
				modalData={modalData}
				onClose={closeModal}
			/>
		</ItemActionsContext.Provider>
	);
}

export default class EventsApp extends React.Component {
	render() {
		return (
			<ListApp
				{...this.props}
			/>
		);
	}
}

function groupItemsBy(item, originalGroupKeys) {
	return originalGroupKeys[item.id] ?? getItemGroupKey(item);
}
function getItemGroupKey(item) {
	// TODO: Add a field to templates that specify which field to group items on
	const ottstart = getValue(item, "start", "ottstart-start-end-settings-rightmenu.ott");
	const key = ottstart ? moment(ottstart).format("YYYYMMDD") : "";
	return key;
}

function sortItemsBy(item, originalSortKeys) {
	return originalSortKeys[item.id] ?? getItemSortKey(item);
}
function getItemSortKey(item) {
	const ottstart = getValue(item, "start", "ottstart-start-end-settings-rightmenu.ott");
	const key = ottstart ? moment(ottstart).valueOf() : "";
	return key;
}

function getGroupTitle(groupKey, resourceLink = "/eventplanner/resources", app = "resources") {
	if (groupKey?.length) {
		
		const hasAccessToResourcesApp = hasAccessToPath([
			{ module: "eventplanner" },
			{ app },
		]);
		const resourcesLink = hasAccessToResourcesApp && (
			<Link
				className="c6-link"
				to={{
					pathname: resourceLink,
					query: {
						date: moment(groupKey).format("YYYY-MM-DD"),
					},
				}}
			>
				Plan resources
			</Link>
		);

		return (
			<span>{renderTodayOrDate(groupKey, true)}&nbsp;{resourcesLink}</span>
		);
	}

	return "Missing OTT start";
}

async function downloadExcel(latestFetchPayload) {
	const payload = {
		...latestFetchPayload,
		_entity: "eventsexcellist",
	};

	const from = payload[">=ott.start"];
	const until = moment(from).add(30, "days").endOf("day");
	payload["<ott.start"] = until.format();

	delete payload.pageIndex;
	delete payload.pageSize;

	const fileName = "eventplanner_export.xlsx";
	payload.fileName = fileName;
	const excel = await CMSAPI.downloadCMSEntities(payload);
	saveBlobToDisc(excel, fileName);
}