import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Checkbox from '@mui/material/Checkbox'

import Const from '../../../core/constants'

import { Item, ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'
import Button from '../../../components/ui/controls/button'

import Actions from '../actions'

const BroadcastList = ({ items, isLoading, onSelectItem, hasSelectedItems, day }) => {
	if (!items?.length) {
		<Empty v2={true} isLoading={isLoading}>Sorry, could not find any changes.</Empty>;
	}

	return (
		<ItemGroup title={day ? moment(day).format(Const.PERIOD_FORMAT) : null} containerClassName="changes-list">
			{items.map(item => {
				return (
					<BroadcastListItem
						key={item.id}
						broadcast={item}
						onSelectItem={onSelectItem}
						hasSelectedItems={hasSelectedItems}
					/>
				);
			})}
		</ItemGroup>
	);
};

BroadcastList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
	})).isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default BroadcastList;

const BroadcastListItem = ({ broadcast, onSelectItem, hasSelectedItems, parent, parentSelectable }) => {
	const disabled = broadcast.status === "Confirmed";
	const selectable = !disabled
		&& onSelectItem
		&& (!parent || parent && !parentSelectable);
	const onSelect = selectable ? () => onSelectItem(broadcast.id) : null;
	const hideUnselectedClass = hasSelectedItems && !broadcast.selected ? "clipboard-hide" : "";
	const childrenHidden = broadcast.children?.[0].broadcastStatus === "Hidden";

	return (
		<>
			<Item
				key={broadcast.id}
				id={broadcast.id}
				disabled={disabled}
				small
				onSelect={onSelect}
				sel={broadcast.selected}
			>
				<div className={`flex flex-dyn-1 change ${broadcast.status.toLowerCase()} ${broadcast.broadcastStatus.toLowerCase()} ${hideUnselectedClass}`}>
					<div className="checkbox-container clipboard-hide">
						{onSelect && (
							<Checkbox
								checked={!!broadcast.selected}
								sx={{ padding: 0 }}
							/>
						)}
					</div>
					<div className="c6-content">
						<h1>
							<span className="status clipboard-hide">
								{parent && <span className="sub-broadcast-mark">-</span>}
								{broadcast.status !== "Confirmed" && (broadcast.status === "Update" ? `${moment(broadcast.confirmedStart).format("HH:mm")}` : broadcast.status)}
							</span>
							<span className="arrow clipboard-hide">&#x25B6;</span>
							<span className="time">{moment(broadcast.start).format("HH:mm")} </span>
							{broadcast.program.title}

							{broadcast.children?.length > 0 && broadcast.status !== "Delete" && (
								<Button
									title={childrenHidden ? "Click to include parts" : "Click to exclude parts"}
									shortTitle={childrenHidden ? "Include parts" : "Exclude parts"}
									hoverTitle={childrenHidden ? "Click to include the program parts in the schedule/EPG." : "Click to exclude the program parts from the schedule/EPG."}
									onClick={(e) => onChildStatusClick(e, broadcast, childrenHidden)}
								/>
							)}
						</h1>
					</div>
					<div className="c6-content clipboard-hide">
						<h1>
							<span className="episode-season-indicator">{broadcast.program.seasonNumber && broadcast.program.seasonNumber != 0 ? "S" : ""}</span>
							<span className="season-number">{broadcast.program.seasonNumber && broadcast.program.seasonNumber != 0 ? broadcast.program.seasonNumber : ""}</span>

							<span className="episode-season-indicator">{broadcast.program.episodeNumber && broadcast.program.episodeNumber != 0 ? "E" : ""}</span>
							<span className="episode-number">{broadcast.program.episodeNumber && broadcast.program.episodeNumber != 0 ? broadcast.program.episodeNumber : ""}</span>
						</h1>
					</div>
				</div>
			</Item>
			{broadcast.children?.map(child => {
				return (
					<BroadcastListItem
						key={child.id}
						broadcast={child}
						onSelectItem={onSelectItem}
						hasSelectedItems={hasSelectedItems}
						parent={broadcast}
						parentSelectable={selectable}
					/>
				);
			})}
		</>
	);
}

function onChildStatusClick(e, broadcast, childrenHidden) {
	e.stopPropagation();

	if (childrenHidden) {
		Actions.unhideBroadcastChildren(broadcast);
	} else {
		Actions.hideBroadcastChildren(broadcast);
	}
}