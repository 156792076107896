import React from 'react'
import { Route, IndexRoute } from 'react-router'

import Module from '../app'

import OrdersApp from './orders/app'

export default class OrdersModule {
	static routes() {
		return (
			<Route path="orders" component={Module} module="ordersadmin">
				<Route path="orders">
					<IndexRoute component={OrdersApp} />
				</Route>
			</Route>
		);
	}
}